import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Layout from "../components/Layout";
import Seo from "../components/Seo";
import ProductHighlightDrPepper from "../components/ProductHighlightDrPepper";
import ProductLinkListHorizonal from "../components/ProductLinkListHorizonal";
import { OutboundLink } from "gatsby-plugin-google-gtag";

const Content = () => {
  const title = "Caffeine Free Dr Pepper";
  const description = "You can still get that authentic Dr Pepper flavor without the caffeine. Dr Pepper Caffeine-Free delivers the original blend of 23 flavors you've come to expect from Dr Pepper.";
  return (
    <Layout>
      <Seo title={title} description={description} />
      <Container>
        <Row>
          <Col>
            <h1>{title}</h1>
            <p>{description}</p>
          </Col>
        </Row>

        <Row>
          <Col sm={8}>
            <h2>Nutrition Facts</h2>
            <p>Size 20 fl oz</p>
            <table class="nutrition-table">
              <thead>
                <tr>
                  <th>Per Serving</th>
                  <th>% DAILY VALUE</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <b>Calories: </b>250
                  </td>
                  <td></td>
                </tr>
                <tr>
                  <td>
                    <b>Total Fat: </b>0 g
                  </td>
                  <td>0%</td>
                </tr>
                <tr>
                  <td>
                    <b>Sodium: </b>100 mg
                  </td>
                  <td>4%</td>
                </tr>
                <tr>
                  <td>
                    <b>Total Carbohydrates: </b>66 g
                  </td>
                  <td>22%</td>
                </tr>
                <tr>
                  <td>
                    <b>Sugar: </b>64 g
                  </td>
                  <td></td>
                </tr>
                <tr>
                  <td>
                    <b>Protein: </b>0 g
                  </td>
                  <td></td>
                </tr>
              </tbody>
            </table>
            <p className="mt-4 mb-3">*Based on a 2000 calorie diet</p>
            <h2 className="mt-4 mb-3">{title} Ingredients</h2>
            <ul>
              <li>Carbonated Water</li>
              <li>High Fructose Corn Syrup</li>
              <li>Caramel Color</li>
              <li>Phosphoric Acid</li>
              <li>Natural and Artificial Flavors</li>
              <li>Sodium Benzoate (preservative)</li>
            </ul>
            <h2>Does Caffeine Free Dr Pepper Have Caffeine?</h2>
            <p>As the name "Caffeine Free Dr Pepper" implies, this product does not contain any Caffeine. So Caffeine Free Regular has 0 mg of caffeine per 8 fluid ounces.</p>

            <h2>How many calories does Dr Pepper have?</h2>
            <p>
              Calorie amount varies depending on package size. The Nutrition Facts panel on each package will list the calorie content. Calories in a 12 fluid ounce serving of Caffeine Free Regular is
              150 calories.
            </p>
            <ul>
              <li>150 Calories per 12 fl regular sized Can</li>
            </ul>

            <h2>How much sugar does Caffeine Free Dr Pepper have?</h2>
            <p>The sugar quantity of Caffeine Free Regular can be found on the Caffeine Free Dr Pepper nutrition facts labels on each product.</p>
            <ul>
              <li>39 grams of Total Sugars which includes 39g of added sugar 12 fl regular sized Can</li>
            </ul>
          </Col>

          {/*
          <Col sm={12} className="mb-5">
            <h2 className="mt-4 mb-3">Available Sizes</h2>
            <ProductHighlightDrPepper />
				  </Col>
*/}
        </Row>

        <Row>
          <Col>
            <ProductLinkListHorizonal title="Products" />
          </Col>
        </Row>
        <Row>
          <Col>
            <h2>References</h2>
            <ol class="reference-listing">
              <li>
                <OutboundLink target={`_BLANK`} href={`https://www.drpepper.com/en/products/drpepper-caffeine-free`}>
                  Caffeine Free Dr Pepper Product Page
                </OutboundLink>
              </li>
              <li>
                <OutboundLink target={`_BLANK`} href={`https://www.drpepper.com/en/faq#17`}>
                  Dr Pepper FAQ Page (Caffeine)
                </OutboundLink>
              </li>
              <li>
                <OutboundLink target={`_BLANK`} href={`https://www.drpepper.com/en/faq#18`}>
                  Dr Pepper FAQ Page (Calories )
                </OutboundLink>
              </li>
            </ol>
            <p>Some values derived from base value.</p>
          </Col>
        </Row>
      </Container>
    </Layout>
  );
};

export default Content;
